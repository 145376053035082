// === Send to print === //
function SendToPrint() {
    window.print();
}

// === Prevent Paste Event === //
function PreventPasteEvent(e) {
    e.preventDefault();
}

function myKeyPress(e) {
    var keynum;

    // IE
    if (window.event) {

        keynum = e.keyCode;
        // Opera/Safari/Chrome
    } else if (e.which) {

        keynum = e.which;

    }

    return String.fromCharCode(keynum);
}

// Return the value of the input
// Params: 
//   - Object
//   - Event
function GetInputValue(i, e) {
    var inputValue = i.value;
    var keyPressed = myKeyPress(e);

    inputValue += keyPressed;
    return inputValue;
}

function MaxLengthCheck(object, event) {
    var inputValue = GetInputValue(object, event);
    if (inputValue.length > object.max.length) {

        object.value = object.value.slice(0, object.max.length);

    }
}

// Set the input value at min and max attribute
function InputValuesCheck(object, event) {
    var currentValue = object.value;
    var newValue = parseInt(GetInputValue(object, event));
    var maxValue = parseInt(object.max, 10);
    var minValue = parseInt(object.min, 10);
    var sel = window.getSelection().toString();

    if (sel === currentValue) {

        return true;

    } else if (sel) {

        newValue = currentValue.replace(sel, '');
        newValue = parseInt((newValue + event.key), 10);
        if (newValue >= maxValue || newValue <= minValue) {

            event.preventDefault();

        }
        return true;
    }

    if (newValue > maxValue || newValue < minValue) {

        event.preventDefault();

    }
}

// Set the default value on inputs if is empty value
function SetInputDefault(object) {
    var inputValue = object.value;
    var minValue = parseInt(object.min);

    if (inputValue === '') {

        object.value = minValue;

    }
}

function IsNumeric(e, object) {
    var event = e || window.event;
    var key = event.keyCode || event.which;
    if (event.keyCode === 8 || event.keyCode === 37 || event.keyCode === 39 ||
        event.keyCode === 36 || event.keyCode === 35 || event.keyCode === 9 || event.keyCode === 46 ||
        (event.ctrlKey && key === 97)
    ) {

        event.returnValue = true;
        return true;

    }

    key = String.fromCharCode(key);
    var regex = /[0-9]|\./;
    if (!regex.test(key) ||
        (object && key === '.' && object.value === '') ||
        (object && key === '.' && object.value.indexOf('.') >= 0)) {

        event.returnValue = false;
        if (event.preventDefault) {

            event.preventDefault();

        }

    }
}

function OnlyNumbers(e) {
    var event = e || window.event;
    var key = event.keyCode || event.which;
    if (event.keyCode === 8 || event.keyCode === 37 || event.keyCode === 38 ||
        event.keyCode === 39 || event.keyCode === 40 || event.keyCode === 36 ||
        event.keyCode === 35 || event.keyCode === 9 || (event.keyCode === 46 && event.key !== '.') ||
        (event.ctrlKey && key === 97)) {

        return true;

    } else if (key < 48 || key > 57) {

        return false;

    } else {

        return true;

    }
}

function MyScrollTo(e, duration) {
    $('html, body').animate({
        scrollTop: $(e).offset().top
    }, duration);
}

function ScrollInElement(selector, duration) {
    $(selector).animate({ scrollTop: 0 }, duration);
}

function GetCurrentTarget(event) {
    return event.currentTarget;
}

$(function () {
    $('a[scrollTo]').click(function () {
        var target = $($(this).attr('scrollTo'));

        if (target.length) {

            $('html, body').animate({
                scrollTop: target.offset().top
            }, 500);
            return false;

        }
    });
});

String.prototype.removeDiacritics = function () {
    var diacritics = [
        [/[\300-\306]/g, 'A'],
        [/[\340-\346]/g, 'a'],
        [/[\310-\313]/g, 'E'],
        [/[\350-\353]/g, 'e'],
        [/[\314-\317]/g, 'I'],
        [/[\354-\357]/g, 'i'],
        [/[\322-\330]/g, 'O'],
        [/[\362-\370]/g, 'o'],
        [/[\331-\334]/g, 'U'],
        [/[\371-\374]/g, 'u'],
        [/[\321]/g, 'N'],
        [/[\361]/g, 'n'],
        [/[\307]/g, 'C'],
        [/[\347]/g, 'c']
    ];
    var _self = this;
    for (var i = 0; i < diacritics.length; i++) {

        _self = _self.replace(diacritics[i][0], diacritics[i][1]);

    }
    return _self;
};

function SanitizeString(str) {
    str = str.trim()
        .removeDiacritics()
        .replace(/[^a-z0-9 \.,_-]/gim, '')
        .replace(/[^a-z0-9\.,_-]/gim, '-')
        .trim();

    return str;
}

function CleanString(str) {
    str = str.trim()
        .replace(/&amp;/g, '')
        .replace(/&lt;/g, '')
        .replace(/&gt;/g, '')
        .replace(/&quot;/g, '')
        .replace(/&#039;/g, '')
        .replace(/<\/?[^>]+(>|$)/g, '');

    return str;
}

function SetFocus(selector) {
    jQuery(selector).focus();
}

function MaxAttrCheck(object) {
    var inputValue = parseFloat(object.value);
    var maxValue = parseFloat(object.max);
    if (inputValue > maxValue) {

        object.value = object.value.slice(0, object.value.length - 1);

    }
}

function MaxLengthAttrCheck(object) {
    if (object.value.length > object.maxLength) {

        object.value = object.value.slice(0, object.maxLength);

    }
}

function ZeroCheck(object) {
    if (object.value.length > 1 && object.value === '00') {

        object.value = '0';

    }
}

function zeroAndMaxCheck(object) {
    ZeroCheck(object);
    MaxLengthAttrCheck(object);
    MaxAttrCheck(object);
}

function GetKendoWindow(selector) {
    return kendo.jQuery(selector).data('kendoWindow');
}
function FilterArray(array, customFunction) {
    return $.grep(array, customFunction);
}
// jscs:disable
var CommonMethods = (function ($, context) {
    // jscs:enable
    /// <summary>
    /// Perform ajax request call
    /// </summary>
    /// <param name='url' type='String'>Url to perform request</param>
    /// <param name='data' type='JSON object'>Data to send to the server</param>
    /// <param name='type' type='String'>Request type (GET, PUT, PATCH, DELETE, etc)</param>
    /// <param name='dataType' type='String'>Data type for request, default 'JSON'</param>
    /// <returns></returns>
    var process = function (url, data, type, dataType, contentType, xhrFields) {
        type = type || 'GET';
        dataType = dataType || 'JSON';
        contentType = contentType || 'application/json; charset=utf-8';
        xhrFields = xhrFields || {};

        return $.ajax({
            cache: false,
            type: type,
            dataType: dataType,
            url: url,
            data: data,
            contentType: contentType,
            xhrFields: xhrFields
        });
    };
    /// <summary>
    /// Added UI Spinner to certain element, the entire element will be blocked with an spinner inside it.
    /// </summary>
    /// <param name='container'>jQuery Element to which will be append loading spinner.</param>
    /// <param name='show'>Either to show spinner or remove it.</param>
    /// <param name='size' default='none'>Spinner size, available values 'small', 'large', 'hl', 'small hl' and 'large hl'.</param>
    /// <returns></returns>
    var uiprogress = function (selector, show, size) {
        var container = $(selector);
        var mask = container.find('.k-loading-mask');
        var support = kendo.support;
        var browser = support.browser;
        var isRtl;
        var leftRight;
        var webkitCorrection;
        var containerScrollLeft;
        var loadingImage;
        var imageHeight;
        var imageWidth;
        size = size || '';

        if (show) {

            if (!mask.length) {

                isRtl = support.isRtl(container);
                leftRight = isRtl ? 'right' : 'left';
                containerScrollLeft = container.scrollLeft();
                webkitCorrection = browser.webkit ?
                    (!isRtl ? 0 : container[0].scrollWidth - container.width() - 2 * containerScrollLeft)
                    : 0;
                container.css('position', 'relative');
                mask = $('<div class="k-loading-mask"><div class="k-loading-image ' +
                    size + '"/><div class="k-loading-color"/></div>')
                    .width('100%').height('100%')
                    .css('top', container.scrollTop())
                    .css(leftRight, Math.abs(containerScrollLeft) + webkitCorrection)
                    .prependTo(container);

                loadingImage = container.find('.k-loading-image');
                imageWidth = loadingImage.width();
                imageHeight = loadingImage.height();

                loadingImage.css({
                    'left': ((mask.width() / 2) - (imageWidth / 2)),
                    'top': ((mask.height() / 2) - (imageHeight))
                });

            }

        } else if (mask) {

            mask.fadeOut(150, function () {
                mask.remove();
                container.css('position', '');
            });

        }
    };
    /// <summary>
    /// Trgiger an event
    /// </summary>
    /// <param name='selector' type='String'>css selector</param>
    /// <param name='event' type='string'>the event to trigger</param>
    /// <returns></returns>
    var trigger = function (selector, event) {
        return $(selector).trigger(event);
    };

    /// <summary>
    /// append html to a existing element
    /// </summary>
    /// <param name='selector' type='String'>css selector</param>
    /// <param name='html' type='string'>html to append</param>
    /// <returns></returns>
    var append = function (selector, html) {
        html = html || '';
        return $(selector).append(html);
    };

    /// <summary>
    /// Init Kendo Validator
    /// </summary>
    /// <param name="element" type="String">valid jquery element</param>
    /// <param name="kendomessages" type="String">Object with kendo custom messages</param>
    /// <param name="kendorules" type="String">Object with kendo custom rules </param>
    /// <param name="kendoerrorTemplate" type="String">string template to display errors</param>
    /// <returns></returns>
    var kendoValidator = function (element, kendomessages, kendorules, kendoerrorTemplate) {
        if (element.length > 0) {
            element.kendoCustomValidator({
                messages: kendomessages,
                rules: kendorules,
                errorTemplate: kendoerrorTemplate
            });
        }
    };

    // get Kendo Modal Window
    var dataKendoValidator = function (selector) {
        return kendo.jQuery(selector).data('kendoCustomValidator');
    };

    /// <summary>
    /// Get an element
    /// </summary>
    /// <param name="selector" type="String">css selector</param>
    /// <returns></returns>
    var getBySelector = function (selector) {
        return $(selector);
    };

    return {
        ProcessRequest: process,
        Progress: uiprogress,
        TriggerEvent: trigger,
        KValidator: kendoValidator,
        GetKendoValidator: dataKendoValidator,
        GetElement: getBySelector,
        AppendHtml: append
    };
})(jQuery, this);

var Shop = Shop || {};

Shop.Browser = {

    //simple dirty mobile OS detection
    isMobile: {
        Android: function () {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function () {
            return (this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows());
        }
    },

    //simple dirty browser detection
    whatBrowser: {
        Chrome: function () {
            return navigator.userAgent.match(/Chrome/i);
        },
        Firefox: function () {
            return navigator.userAgent.match(/Firefox/i);
        },
        Safari: function () {
            return navigator.userAgent.match(/Safari/i);
        },
        Explorer: function () {
            return navigator.userAgent.match(/MSIE/i);
        },
        //detection for the 4 major supported browsers
        all: function () {
            return (this.Chrome() || this.Firefox() || this.Safari() || this.Explorer());
        }
    },

    dimensions: {
        Height: function () {
            return $(window).height();
        },
        Width: function () {
            return $(window).width();
        }

    }
};
/* jshint ignore:start */
// jscs:disable
Array.prototype.indexOf || (Array.prototype.indexOf = function (d, e) {
    var a;
    if (null === this) throw new TypeError('"this" is null or not defined');
    var c = Object(this),
        b = c.length >>> 0;
    if (0 === b) return -1;
    a = +e || 0;
    Infinity === Math.abs(a) && (a = 0);
    if (a >= b) return -1;
    for (a = Math.max(0 <= a ? a : b - Math.abs(a), 0); a < b;) {
        if (a in c && c[a] === d) return a;
        a++
    }
    return -1
});

Element.prototype.remove || (Element.prototype.remove = function () {
    this.parentElement.removeChild(this);
});

NodeList.prototype.remove || (NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
    for (var i = this.length - 1; i >= 0; i--) {
        if (this[i] && this[i].parentElement) {
            this[i].parentElement.removeChild(this[i]);
        }
    }
});
Array.prototype.filterByKeyValue = function (nameKey, value) {
    var _response = [];
    for (var i = 0; i < this.length; i++) {
        if (this[i][nameKey] === value) {
            _response.push(this[i]);
        }
    }
    return _response.length === 1 ? _response[0] : _response;
};
// jscs:enable
/* jshint ignore:end */

function GetDisplayName(data) {
    var _name = '';

    _name = data.recipient ||
        (data.firstName ? data.firstName + (data.lastName ? ' ' + data.lastName : '') : '');

    return (_name ? _name += ' ' : '');
}

function GetFullAddress(data) {
    var _street = ((data.line1 && data.line1 !== null) ? data.line1 + ' ' : '') +
        ((data.line2 && data.line2 !== null) ? data.line2 + ' ' : '') +
        ((data.line3 && data.line3 !== null) ? data.line3 + ' ' : '') +
        (data.displayRecipentOnAddress && (data.line4 && data.line4 !== null) ? data.line4 + ' ' : '');

    if (_street) { _street += '<br/>'; }

    return _street +
        ((data.city && data.city !== null) ? data.city + ', ' : '') +
        ((data.stateProvinceTerritory && data.stateProvinceTerritory !== null) ? data.stateProvinceTerritory + ' ' : '') +
        ((data.postalCode && data.postalCode !== null) ? data.postalCode : '');
}

function GetPrimaryAddress(data) {

    if (data.length > 0) {
        var i = 0;

        for (; i < data.length; i++) {

            if (data[i].isPrimary) {

                return data[i];

            }

        }

        return data[0];

    }

    return {};
}
function SpecialChars(e, object) {

    var _key = e.which || e.keyCode || 0;
    var _code = !e.charCode ? e.which : e.charCode;

    var _allowedKeys = [8, 46]; // Left  / Right / Backspace, Delete keys
    if (_allowedKeys.indexOf(_key) !== -1 || !_code) {

        return true;

    }

    if (object.value.length === 0 && _key === 32) {

        e.preventDefault();
        return false;

    }

    var regex = new RegExp("^[a-zA-ZÀ-ž\s ]+$");
    var str = String.fromCharCode(_code);
    if (regex.test(str)) {

        return true;

    }

    e.preventDefault();
    return false;
}
/// <summary>
/// Validate keypress input based on sent regex, if input doesn't match regex, it will prevent default.
/// </summary>
/// <param name='e' type='event'>Input event</param>
/// <param name='object' type='Object'>Input itself</param>
/// <returns></returns>
function OnInputKeyPress(e, object) {

    var _key = e.which || e.keyCode;
    if (object.value.length === 0 && e.which === 32) {

        e.preventDefault();
        return false;

    }

    var _exclusions = [8, 9, 37, 38, 39, 40]; /*Add exceptions here */
    if (_exclusions.indexOf(_key) > -1) {

        return true;

    }

    var _pattern = object.dataset.pattern || false;

    if (!_pattern) {

        return true;

    }

    var regex = new RegExp(_pattern);
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);


    if (regex.test(str)) {

        return true;

    }

    e.preventDefault();
    return false;
}
(function ($) {
    if (kendo.ui.hasOwnProperty('Validator')) {

        var CustomValidator = kendo.ui.Validator.extend({
            options: {
                name: 'CustomValidator'
            },
            hideErrors: function () {
                var that = this;
                var _class = 'k-invalid';
                var element = that.element;
                if (element.is('input')) {

                    element.removeClass(_class);
                } else {

                    element.find('.' + _class).removeClass(_class);
                }
            },
            resetForm: function () {
                var that = this;
                var element = that.element;
                if (element.is('input')) {
                    element.val('');
                } else {
                    element.find('input').val('');
                }
            }
        });
        kendo.ui.plugin(CustomValidator);

    }
})(jQuery);
// Reference http://stackoverflow.com/questions/1226574/disable-pasting-text-into-html-form
// Register onpaste on inputs and textareas in browsers that don't
// natively support it.
(function () {
    var onload = window.onload;

    window.onload = function () {
        if (typeof onload === "function") {
            onload.apply(this, arguments);
        }

        var fields = [];
        var inputs = document.getElementsByTagName("input");
        var textareas = document.getElementsByTagName("textarea");
        var i;

        for (i = 0; i < inputs.length; i++) {
            fields.push(inputs[i]);
        }

        for (i = 0; i < textareas.length; i++) {
            fields.push(textareas[i]);
        }

        var oninputOverride = function () {
            if (typeof oninput === "function") {
                oninput.apply(this, arguments);
            }

            if (typeof this.previousValue === "undefined") {
                this.previousValue = this.value;
            }

            var pasted = (Math.abs(this.previousValue.length - this.value.length) > 1 && this.value !== "");

            if (pasted && !this.onpaste.apply(this, arguments)) {
                this.value = this.previousValue;
            }

            this.previousValue = this.value;
        };
        for (i = 0; i < fields.length; i++) {
            var field = fields[i];

            if (typeof field.onpaste !== "function" && !!field.getAttribute("onpaste")) {
                /* jshint ignore:start */
                field.onpaste = eval("(function () { " + field.getAttribute("onpaste") + " })");
                /* jshint ignore:end */
            }

            if (typeof field.onpaste === "function") {
                var oninput = field.oninput;

                field.oninput = oninputOverride;

                if (field.addEventListener) {
                    field.addEventListener("input", field.oninput, false);
                } else if (field.attachEvent) {
                    field.attachEvent("oninput", field.oninput);
                }
            }
        }
    };
})();
function Localizer(key) {
    Shop = Shop || {};
    Shop.Localization = Shop.Localization || {};

    return Shop.Localization[key] || ('Missing-' + key);
}