
(function (locale) {

    switch (locale) {
        case 'de-DE':
            if (kendo.ui.Pager) {
                kendo.ui.Pager.prototype.options.messages = $.extend({}, kendo.ui.Pager.prototype.options.messages, {
                    allPages: 'Alle',
                    display: '{0} - {1} von {2} Seite',
                    empty: 'keine Daten',
                    first: 'Zur ersten Seite',
                    itemsPerPage: 'Elemente pro Seite',
                    last: 'Zur letzten Seite',
                    next: 'Zur nächsten Seite',
                    of: 'von {0}',
                    page: 'Seite',
                    previous: 'Zur vorherigen Seite',
                    refresh: 'Aktualisieren',
                    morePages: 'Weitere Seiten'
                });
            }
            break;
        case 'pt-PT':
            if (kendo.ui.Pager) {
                kendo.ui.Pager.prototype.options.messages = $.extend({}, kendo.ui.Pager.prototype.options.messages, {
                    allPages: 'tudo',
                    display: '{0} - {1} de {2} página',
                    empty: 'sem dados',
                    first: 'Para a primeira página',
                    itemsPerPage: 'Elemente pro Seite',
                    last: 'Elementos por página',
                    next: 'Para a próxima página',
                    of: 'de {0}',
                    page: 'página',
                    previous: 'Para a página anterior',
                    refresh: 'atualizar',
                    morePages: 'Mais páginas'
                });
            }
            break;
        case 'it-IT':
            if (kendo.ui.Pager) {
                kendo.ui.Pager.prototype.options.messages = $.extend({}, kendo.ui.Pager.prototype.options.messages, {
                    allPages: 'All',
                    display: '{0} - {1} di {2} resultati',
                    empty: 'Nessun elemento da visualizzare',
                    first: 'Vai alla prima pagina',
                    itemsPerPage: 'elementi per pagina',
                    of: 'di {0}',
                    page: 'Pagina',
                    previous: 'Vai alla pagina precedente',
                    refresh: 'Aggiorna'
                });
            }
            break;
        case 'pt-PT':
            if (kendo.ui.Pager) {
                kendo.ui.Pager.prototype.options.messages = $.extend({}, kendo.ui.Pager.prototype.options.messages, {
                    display: '{0} - {1} de  {2} resultados'
                });
            }
            break;
        case 'es-MX':
        case 'es-CO':
        case 'es-ES':
            if (kendo.ui.Pager) {
                kendo.ui.Pager.prototype.options.messages = $.extend({}, kendo.ui.Pager.prototype.options.messages, {
                    allPages: 'Todas',
                    display: '{0} - {1} de {2} resultados',
                    empty: 'No hay registros.',
                    first: 'Ir a la primera página',
                    itemsPerPage: 'ítems por página',
                    of: 'di {0}',
                    page: 'Página',
                    previous: 'Ir a la página anterior',
                    refresh: 'Actualizar',
                    last: 'Ir a la última página',
                    next: 'Ir a la página siguiente'
                });
            }
            break;
    }
})(Shop.Locale);