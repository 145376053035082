
kendo.data.binders.class = kendo.data.Binder.extend({
    init: function (target, bindings, options) {
        kendo.data.Binder.fn.init.call(this, target, bindings, options);

        // get list of class names from our complex binding path object
        this._lookups = [];
        for (var key in this.bindings.class.path) {

            if (this.bindings.class.path.hasOwnProperty(key)) {
                this._lookups.push({
                    key: key,
                    path: this.bindings.class.path[key]
                });
            }

        }
    },

    refresh: function () {
        var lookup;
        var value;

        for (var i = 0; i < this._lookups.length; i++) {

            lookup = this._lookups[i];

            // set the binder's path to the one for this lookup,
            // because this is what .get() acts on.
            this.bindings.class.path = lookup.path;
            value = this.bindings.class.get();

            // add or remove CSS class based on if value is truthy
            if (value) {

                $(this.element).addClass(lookup.key);

            } else {

                $(this.element).removeClass(lookup.key);

            }

        }
    }
});

kendo.data.binders.fadeIn = kendo.data.Binder.extend({
    refresh: function () {
        var value = this.bindings.fadeIn.get();

        if (value) {

            $(this.element).fadeIn(500);

        } else {

            $(this.element).hide();

        }
    }
});

kendo.data.binders.slide = kendo.data.Binder.extend({
    refresh: function () {
        var value = this.bindings.slide.get();

        if (value) {

            $(this.element).slideDown();

        } else {

            $(this.element).slideUp();

        }
    }
});
kendo.data.binders.disableContent = kendo.data.Binder.extend({
    refresh: function () {
        var value = this.bindings.disableContent.get();

        if (value) {

            $(this.element).attr('disabled', true);
            $(this.element).find(':input, a').attr('disabled', true);

        } else {

            $(this.element).removeAttr('disabled');
            $(this.element).find(':input, a').removeAttr('disabled');

        }
    }
});

kendo.data.binders.removeElement = kendo.data.Binder.extend({
    refresh: function () {
        var value = this.bindings.removeElement.get();

        if (value) {

            $(this.element).remove();

        }
    }
});
kendo.data.binders.removeAttr = kendo.data.Binder.extend({
    init: function (target, bindings, options) {
        kendo.data.Binder.fn.init.call(this, target, bindings, options);

        // get list of class names from our complex binding path object
        this._lookups = [];
        for (var key in this.bindings.removeAttr.path) {
            if (this.bindings.removeAttr.path.hasOwnProperty(key)) {

                this._lookups.push({
                    key: key,
                    path: this.bindings.removeAttr.path[key]
                });
            }

        }
    },

    refresh: function () {
        var lookup;
        var value;

        for (var i = 0; i < this._lookups.length; i++) {

            lookup = this._lookups[i];

            // set the binder's path to the one for this lookup,
            // because this is what .get() acts on.
            this.bindings.removeAttr.path = lookup.path;
            value = this.bindings.removeAttr.get();

            // add or remove attributes based on if value is truthy
            if (value) {

                $(this.element).removeAttr(lookup.key);

            }

        }
    }
});
kendo.data.binders.shake = kendo.data.Binder.extend({
    refresh: function () {
        var value = this.binding.shake.get();
        var element = this.element;
        if (value) {

            $(element).css({ 'position': 'relative' });
            for (var x = 1; x <= 3; x++) {

                $(element)
                    .animate({ left: -10 }, 10)
                    .animate({ left: 0 }, 50)
                    .animate({ left: 10 }, 10)
                    .animate({ left: 0 }, 50);

            }

        }
    }
});