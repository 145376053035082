
var SetUpPageSize = function () {

    this.headerHeight = function () {
        return $('header').innerHeight();
    };

    this.setMarginTop = function () {
        $('.header-pad').css({
            'margin-top': this.headerHeight
        });
    };

    this.setTop = function () {
        var viewPortWidth = $(window).width();
        if (viewPortWidth > 992) {
            $('.dropdown-menu').css({
                'top': this.headerHeight - 1
            });
        }
    };

    this.handler = function () {
        this.setMarginTop();
        this.setTop();
    };

};

$(document).ready(function () {
    var setUp = new SetUpPageSize();
    setUp.handler();
});