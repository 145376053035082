
var ShopUrls = ShopUrls || {};

jQuery.extend(ShopUrls, {
    GetStoreUrl: function (storeName) {
        return 'https://' + storeName + '.zuswqasvcfabf2.goherbalife.com';
    },
    IntroduceMe: function () {
        return '/ShopNow/ShopNow/IntroduceMe';
    },
    ShopNowAPI: function (filter) {
        return '/ShopNow/API/ShopNowV1/' + Shop.Locale;
    }
});