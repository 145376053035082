
function ShopNowViewModel() {

    // Public Properties
    this.TotalPageSize = 0;
    this.Distributors = [];
    this.Pagenumbers = [];
    this.LastSearch = '';
    this.Search = '';
    this.VisibleNotFound = false;
    this.VisibleResults = false;
    this.Loading = false;
    this.DSEmail = '';

    // private properties
    var _Domain = '.goherbalife.com';
    var _ToSearch = '';
    var _SearchParameterName = '';
    var _Self;

    this.DistributorsDataSource = new kendo.data.DataSource({
        serverPaging: true,
        pageSize: 5,
        page: 1,
        schema: {
            total: 'totalStoreCount',
            parse: function (response) {
                var _dsList = [];
                for (var i = 0; i < response.storeList.length; i++) {

                    var _imagePath = response.storeList[i].distributorPhoto;
                    if (!_imagePath) {

                        _imagePath = '/ShopNow/images/ds-default.jpg';

                    }
                    _dsList.push({
                        'thumbnailImagePath': _imagePath,
                        'fullName': response.storeList[i].contactName,
                        'storeName': response.storeList[i].storeName,
                        'phone': response.storeList[i].phoneNumber || '',
                        'email': response.storeList[i].email || '',
                        'dsId': response.storeList[i].distributorId,
                        'locale': Shop.Locale
                    });

                }
                response.data = _dsList;
                return response;
            },
            data: 'data'
        },
        transport: {
            read:
            {
                url: ShopUrls.ShopNowAPI()
            },
            parameterMap: function (data, action) {
                return {
                    searchParameterName: _SearchParameterName,
                    searchStoreName: _ToSearch,
                    pageNumber: data.page
                };
            }
        },
        requestStart: function (e) {
            _ToSearch = _Self.get('Search').trim();
            _SearchParameterName = _Self.get('selectedSearchOption') || '';
            CommonMethods.Progress('#search-form', true);
        },
        requestEnd: function (e) {
            var _total = '';
            if (e && e.response) {
                _total = e.response.totalStoreCount;
            }
            _Self.set('VisibleResults', true);
            _Self.set('MatchesLength', _total);
            if (!_total) {

                _Self.set('VisibleNotFound', true);
                _ToSearch = '';
                _SearchParameterName = '';

            }
            CommonMethods.Progress('#search-form', false);
        }
    });
    this.load = function () {
        var _form = CommonMethods.GetElement('#customer-form');
        var that = this;
        _Self = that;
        _Self.set('selectedSearchOption', 'storename');
        CommonMethods.KValidator(_form, {}, {}, '<span class="error icon-alert-ln-1">#: message #</span>');
    };
    this.IsSubmitDisabled = function () {
        var that = this;
        return that.get('DSEmail') === '';
    };
    this.OnClickSearchDistributors = function () {
        var that = this;
        that.set('Distributors', []);
        that.set('VisibleNotFound', false);
        var _search = that.get('Search') || '';
        var _toSearch = _ToSearch;
        if (_toSearch && _search.toUpperCase() !== _toSearch.toUpperCase()) {

            that.get('DistributorsDataSource').page(1);

        } else {

            that.get('DistributorsDataSource').read();

        }
    };
    this.OnChangeSearch = function (e) {
        var that = this;
        that.set('VisibleResults', false);
        if (e.keyCode === 13 && !that.IsSearchDisabled()) {
            that.OnClickSearchDistributors();
        }
    };
    this.IsSearchDisabled = function () {
        var that = this;
        var _toSearch = that.get('Search');
        return !(_toSearch.length >= 2 && _toSearch.length <= 100);
    };
    this.IntroduceMe = function () {
        var that = this;
        that.set('Loading', true);
        initProgressBar();
        initShowShopNowMessages();
        setTimeout(function () {
            document.getElementById('introduce-me-form').submit();
        }, 9000);        
    };
    this.ShowFindStoreModal = function () {
        var that = this;
        that.CleanModal();
        GetKendoWindow('#modal-find-store').center().open();
    };
    this.OnFindStore = function () {
        var _form = CommonMethods.GetKendoValidator('#customer-form');

        if (!_form.validate()) {

            return false;

        } else {

            CommonMethods.Progress('#modal-find-store', true);
            document.getElementById('customer-form').submit();

        }
    };
    this.OnCloseModal = function () {
        var that = this;
        that.CleanModal();
    };
    this.CleanModal = function () {
        var that = this;
        that.set('DSEmail', '');
        var _form = CommonMethods.GetKendoValidator('#customer-form');
        _form.hideErrors();
        _form.hideMessages();
    };
    this.ConnectStore = function (e) {
        var that = this;
        var _storeName = e.data.storeName || '';
        var _storeUrl = _BuildStoreUrl(_storeName);
        if (_storeUrl) {

            that.set('Loading', true);
            initProgressBar();
            initShowShopNowMessages();
            _CreateDsCookie(e.data.dsId);
            setTimeout(function () {
                location.href = _storeUrl;
            }, 9000);  

        }
    };
    var _BuildStoreUrl = function (storeName) {
        storeName = storeName || '';
        if (!storeName) {

            return false;

        }
        return location.protocol + '//' + storeName + _GetServer() +
            _Domain + '/Catalog/Home/Index/' + Shop.Locale + '/?ShopAssigmentType=Selected by User&Events=event17';
    };
    var _GetServer = function () {
        return (location.host.split('.').length > 3) ? '.' + location.host.split('.')[1] : '';
    };
    var _CreateDsCookie = function (dsId) {
        $.cookie('LastVisitedDsInfo', dsId + ':' + Shop.Locale, { path: '/', domain: _Domain, expires: 30 });
    };

    this.IntroduceMeWithoutLoadingPageBar = function () {
        $('#introduce-me-button').attr('disabled', 'disabled');
        document.getElementById('introduce-me-form').submit();
    };

    this.ConnectStoreWithoutLoadingPageBar = function (e) {
        var _storeName = e.data.storeName || '';
        var _storeUrl = _BuildStoreUrl(_storeName);
        if (_storeUrl) {

            _CreateDsCookie(e.data.dsId);
            location.href = _storeUrl;

        }
    };
}